import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLDClient, useFlags } from 'gatsby-plugin-launchdarkly'

// Create User Context
const UserContext = createContext({
  user: null,
  LDFlags: null,
  signOut: () => {},
})

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const ldClient = useLDClient()
  const LDFlags = useFlags()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loggedInUser = localStorage.getItem('user')
      if (loggedInUser) {
        setUser(JSON.parse(loggedInUser))
      }
    }
  }, [])

  useEffect(() => {
    const product = process.env.PRODUCT === 'clair' ? 'Clair' : 'OnLabel'
    if (ldClient && user) {
      ldClient.identify({
        kind: 'user',
        key: user.pk,
        email: user.email,
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        plan: user.plan,
        isTrial: user.is_trial,
        studentStatus: user.student_status,
        platform: product,
        anonymous: false,
      })
    } else if (ldClient && !user) {
      ldClient.identify({
        kind: 'user',
        anonymous: true,
      })
    }
  }, [ldClient, user])

  const signOut = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('user') // Remove user information from localStorage
      window.localStorage.removeItem('token')
      setUser(null) // Set user state to null
    }
  }

  return (
    <UserContext.Provider value={{ user, LDFlags, setUser, signOut }}>
      {children}
    </UserContext.Provider>
  )
}
